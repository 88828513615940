import {
  elementType, func, oneOfType, shape, string, bool,
} from 'prop-types';
import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import BEMHelper from 'react-bem-helper';
import { useTranslation } from 'react-i18next';
import './modal.scss';

const KEY_ESC = 27;

const bem = new BEMHelper({
  name: 'modal',
});

export const Modal = ({
  title,
  isPrompt,
  componentProps,
  Component,
  closeCallback,
}) => {
  const props = (componentProps instanceof Function) ? componentProps() : componentProps;
  const dialog = useRef(null);
  const { t } = useTranslation();
  const [shouldClose, setShouldClose] = useState(false);

  const onClose = useCallback(() => !isPrompt && setShouldClose(true), [isPrompt, setShouldClose]);

  const onKeyDown = useCallback(({ keyCode }) => {
    switch (keyCode) {
      case KEY_ESC:
        if (isPrompt) {
          break;
        }

        setShouldClose(true);
        break;

      default:
        break;
    }
  }, [isPrompt, setShouldClose]);

  useEffect(() => {
    const trigger = document.activeElement;
    const interactive = dialog.current.querySelector(`
      a[href],
      area[href],
      input:not([disabled]),
      select:not([disabled]),
      textarea:not([disabled]),
      button:not([disabled]),
      [tabindex="0"]
    `);
    interactive.focus();

    return () => {
      // We need to double defer the focusing because the react render/update
      // cycle will update the `inert` property after the intended `focus()` call.
      // So we try to set a focus for a child element inside a inert=true container.
      // We need to "wait" until the page contents are focusable again:
      window.requestAnimationFrame(() => {
        window.requestAnimationFrame(() => {
          trigger.focus();
        });
      });
    };
  }, []);

  return (
    <div {...bem(null, { 'is-closing': shouldClose })} onAnimationEnd={shouldClose ? closeCallback : undefined}>
      <div {...bem('overlay')} tabIndex="-1" onClick={onClose} role="presentation" />
      <div // eslint-disable-line jsx-a11y/no-noninteractive-element-interactions
        {...bem('dialog')}
        ref={dialog}
        aria-modal="true"
        role="dialog"
        aria-label={title}
        onKeyDown={onKeyDown}
      >
        <div {...bem('contents')}>
          {!isPrompt && (
            <div {...bem('controls')}>
              <button
                {...bem('button')}
                type="button"
                title={t('Close this dialog')}
                onClick={onClose}
              >
                <span {...bem('button__icon')} />
                <span {...bem('button__label')}>{t('Close')}</span>
              </button>
            </div>
          )}
          <Component {...props} />
        </div>
      </div>
    </div>
  );
};

Modal.propTypes = {
  title: string.isRequired,
  isPrompt: bool,
  componentProps: oneOfType([func, shape({})]),
  Component: elementType.isRequired,
  closeCallback: func.isRequired,
};

Modal.defaultProps = {
  isPrompt: false,
  componentProps: null,
};
